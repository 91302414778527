<template>
  <div>
    <Toast
      position="top-right"
      :breakpoints="{ '920px': { width: '100%', right: '0', left: '0' } }"
    />
    <Menubar :model="items">
      <template #start>
        <router-link :to="{ name: 'Home' }">
          <img
            alt="logo"
            src="./assets/images/islaeducativa.png"
            height="32"
            class="p-mr-2"
          />
        </router-link>
      </template>
    </Menubar>
    <router-view class="view" />
    <div class="text-center footer_lg1 pt-4">
      <div
        class="flex-1 md:flex-none flex align-items-center justify-content-center row pb-40 pt-4 footer_lg"
      >
      <div class="col-sm-4 col-md-3">
          <ul class="list-footer">
            <li class="col_lg_20"><img src="./assets/images/iconomapa.jpg" alt="Cómo funciona"><label> Av. Paseo de las Palmas 735 </label></li>
          </ul>
        </div>
        <div class="col-sm-4 col-md-3 pb-20">
          <ul class="list-footer">
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Terms' }">
                Términos y condiciones
              </router-link>
            </li>
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Privacy' }">
                Aviso de privacidad
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-sm-4 col-md-3 col-md-offset-1">
          <ul class="list-footer">
            <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Faq' }">
                Preguntas frecuentes
              </router-link>
            </li>
            <!-- <li>
              <i class="fas fa-arrow-right list"></i>
              <router-link :to="{ name: 'Contact' }">
                Contacto
              </router-link>
            </li> -->
          </ul>
        </div>
        <div class="col-sm-12 col-md-2 mb-4">
          <p class="p p-fb"><label>Síguenos en:</label></p>
          <a :href="fbPage" target="_blank">
            <Button
              icon="pi-facebook"
              iconPos="right"
              class="p-button-raised p-button-rounded"
              ><i class="fab fa-facebook-f"></i
            ></Button>
          </a>
        </div>
        <div class="row ">
          <div class="col-lg-12 col-sm-11">
            <label class="text-sm">
              <p class="mb-0 pb-0">{{ year }} © {{ name }}</p>
              <p class="text-sm">Todos los derechos reservados. Al usar este
                  sitio, aceptas nuestra política de privacidad, así como nuestros
                  términos y condiciones.
              </p>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
export default {
  components: {
    Menubar: Menubar,
    Button: Button,
    Toast: Toast,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
      fbPage: process.env.VUE_APP_FB_PAGE,
      items: [
        {
          label: "Inicio",
          to: "/#home",
        },
        {
          label: "Cómo funciona",
          to: "/#work",
        },
        {
          label: "Beneficios",
          to: "/#benefits",
        },
        {
          label: "Quiénes somos",
          to: "/quienes-somos",
        },
      ],
    };
  },
  setup() {
    const toast = useToast();
    const year = new Date().getUTCFullYear();
    return {
      toast,
      year,
    };
  },
};
</script>

<style>
.list-footer {
  list-style-type: none;
  text-align: left;
}

.list {
  color: blue !important;
  font-size: 12px;
}
.p {
  color: white;
  font-size: 14px;
}

.bg-black {
  background-color: #000;
}

i {
  font-size: 19px;
}

.view {
  width: 100%;
}

.p-menubar {
  padding: 0.9rem !important;
  position: fixed !important;
  z-index: 100;
  width: 100%;
  top: 0;
  background: #ffffff !important;
  border-color: #ffffff !important;;
}

.p-menubar .p-menubar-button {
  position: absolute;
  right: 5%;
}

.p-menubar .p-menubar-button .pi {
  font-size: 2rem;
}

.p-menubar .p-menubar-root-list {
  position: absolute;
  right: 0px;
}

@media (min-width: 1825px) {
  .footer {
    padding: 0 25rem;
  }
}

.p-fb {
  margin-top: 0;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #1973b8 !important;
    font-size: 20px;
    font-family: 'Geomanist' ;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 0.25rem !important;
    color: #495057;
    border-radius: 3px;
    transition: box-shadow 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

</style>
