
import { defineComponent } from "vue";
import Button from "primevue/button";
import * as FacebookServices from "../api/helpers/Facebook";
export default defineComponent({
  name: "Home",
  components: {
    Button,
  },
  mounted() {
    FacebookServices.pageView();
    sessionStorage.clear();
    localStorage.clear();
    const urlParams = new URLSearchParams(window.location.search);
    sessionStorage.setItem("SID", urlParams.get("SID") || "");
    sessionStorage.setItem("CHID", urlParams.get("CHID") || "");
  },
  methods: {
    go() {
      this.$router.push("/cuestionario");
    },
  },
});
